import React, { Component } from 'react';
import {HelpDIv, Helpbutton, HelpMain, HelpReward, TextDiv, HelpcontentDIv, Helptext, HelpRewardMobile} from './Banner';
import computer from './computer.svg'
export default class ResultBanner extends Component {
  render() {
    return (
      <HelpMain>
        <HelpDIv>
        <HelpcontentDIv>
            <div>
              <img src={computer} alt="Computer" style={{height: '100px'}}/>
            </div>
            <TextDiv>
              <Helptext>Need More Help?</Helptext>
              <Helpbutton><a target="_blank" href="https://help.bestchoiceproducts.com/hc/en-us" style={{ color: '#ffffff' }}>VISIT OUR HELP CENTER</a></Helpbutton>
            </TextDiv>
          </HelpcontentDIv>
        </HelpDIv>

        <HelpReward>
          <a href="https://bestchoiceproducts.com/pages/rewards" target="_blank"><img src="https://cdn.shopify.com/s/files/1/0636/7991/files/rewards-promo-d_1330x550.jpg?v=1529339969" style={{ width: '100%' }}/></a>
        </HelpReward>
        <HelpRewardMobile>
          <a href="https://bestchoiceproducts.com/pages/rewards" target="_blank"><img src="https://cdn.shopify.com/s/files/1/0636/7991/files/rewards-promo-m_500x500.jpg?v=1529339982" style={{ width: '100%' }}/></a>
        </HelpRewardMobile>
      </HelpMain>
    )
  }
}
