import React, { Component } from 'react';
import LoadingScreen from './client/order/LoadingScreen'
import Result from './client/order/Result'
import RecordNot from './client/order/RecordNot'
import Label from '../src/client/order/Label'
import UpsAndOntrack from './client/order/UpsAndOntrack';

export default class Ordertrack extends Component {


  constructor(props) {
    super(props);
      this.state = {
        value: '',
        carrier : '',
        orderNumber : '',
        isLoading: false,
        isError : false,
      };

  }
  componentDidMount() {
      fetch(`/query/${this.props.match.params.id}/${this.props.match.params.zip}`)
        .then(res => res.json())
        .then(user =>{
          if(typeof(user.data) === "string"){
            this.setState({
              isLoading: true ,
              isError : true
            });
          }else{
            (user.data.length > 2) ? 
            this.setState({
              value: JSON.parse(user.data[0]),
              orderNumber : user.data[1],
              carrier : 'FEDEX',
              isLoading: true
            }) : this.setState({
              carrier : 'other',
              orderNumber : user.data[0],
              isLoading: true
            })
          }
    });
  }

    render() {

      return (
        <div>
          {!this.state.isLoading ? (
            <LoadingScreen />
          ) : (
            this.state.carrier == "FEDEX" ? (
              this.state.isError || this.state.value.TrackReply.CompletedTrackDetails.TrackDetails.Notification.Severity == "ERROR"  ? (
                <RecordNot marketid={this.props.match.params.id}/>
              ) : (
                Array.isArray(this.state.value.TrackReply.CompletedTrackDetails.TrackDetails.Events) ? (
                  <Result tracking={this.state.value.TrackReply.CompletedTrackDetails.TrackDetails} marketid={this.props.match.params.id} ordernumber={this.state.orderNumber}/>
                ):(
                  <Label tracking={this.state.value.TrackReply.CompletedTrackDetails.TrackDetails} marketid={this.props.match.params.id} ordernumber={this.state.orderNumber}/>
              )
            )
            ) : (
              this.state.orderNumber == null || this.state.orderNumber == undefined ? (
                <RecordNot marketid={this.props.match.params.id}/>
              ) : (
                <UpsAndOntrack ordernumber={this.state.orderNumber} marketid={this.props.match.params.id} />
              )
            )
          )}
        </div>
      );
    }
}
