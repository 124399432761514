import React, { Component } from 'react';
import styled from 'styled-components';

export default class ActivityMap extends Component {

  render() {
     const Div = styled.div`
      display : flex;
      margin: 0px 18px;
      justify-content:center;
      border-bottom: solid 0.5px #ececec;
      padding: 15px 0px;
    `;
    const DivIn = styled.div`
        flex:1;
        font-size: 14px;
      `;
    const Divthird = styled.div`
        flex:1;
        font-size: 12px;
        color: #9b9b9b;
        text-align:right;
      `;
      const Button = styled.button`
        display: block;
        margin: 17px auto;
        cursor: pointer;
        border:0;
        font-size: 12px;
        color: #9b9b9b;
      `;

        const P = styled.p`
          font-size: 12px;
          color: #9b9b9b;
        `;
        var acti = this.props.activityevent;


    return (
      <div>
        <Div>
          <DivIn> {acti.EventDescription}</DivIn>
        </Div>
      </div>
    );
  }
}
