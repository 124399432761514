import styled from 'styled-components';

export const OrderStatus = styled.div`
    width: 100%;
    height: 250px;
    border-radius: 2px;
    border: solid 1px #ececec;
    position: relative;
    @media (max-width: 731px) {
      margin-bottom: 20px;
      height: 270px;
    }
`;

export const UpsOrderStatus = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: solid 1px #ececec;
    position: relative;
    @media (max-width: 731px) {
      margin-bottom: 20px;
    }
`;
export const TrackingInfo = styled.div`
    width: 100%;
    height: 255px;
    border-radius: 2px;
    margin-top:10px;
    border: solid 1px #ececec;
    position: relative;
    @media (max-width: 731px) {
      margin-bottom: 20px;
      height: 230px;
    }
`;
export const OrderActivity = styled.div`
      width: 100%;
      height: 515px;
      overflow: auto;
      border-radius: 2px;
      border: solid 1px #ececec;
`;

export const Rewardimag = styled.img`
  @media (min-width: 732px) {
    width : 100%;
    height: 220px;
    margin-top:15px;
    display:block;
  }
  @media (max-width: 731px) {
    display:none;
  }
`;
export const Rewardimage = styled.img`
  @media (min-width: 732px) {
    display:none;
  }
  @media (max-width: 731px) {
    display:block;
    width: 100%;
    padding: 0px 50px;
    margin-top: 20px;
    margin-bottom: 100px;
  }

`;

export const Orderdiv = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  display: grid;
  grid-template-columns: 49% 50%;
  max-width: 1260px;
  margin: 50px auto 20px auto;
  justify-content: space-between;
  @media (max-width: 731px) {
    grid-row-gap: 10px;
    grid-template-columns: 100%;
  }
`;

export const HeaderCard = styled.p`
  font-family: Montserrat Medium;
  border-bottom: solid 1px #ececec;
  text-align: left;
  font-size: 14px;
  padding: 10px 18px;
  font-weight: 400;
  background-color: #282828;
  color:#fff;
`;

export const Orderflex = styled.div`
  display:block;
  justify-content: space-between;
  padding: 0px 18px;
  @media (max-width: 731px) {
      display:block;
  }
`;
export const UpsOrderflex = styled.div`
  display:block;
  justify-content: space-between;
  padding: 20px 40px 34px 20px;
  @media (max-width: 731px) {
      display:block;
  }
`;
export const EstimateDiv = styled.div`
    @media (max-width: 731px) {
        margin-bottom: 20px;
    }
`;
export const TextDiv = styled.div`
  font-size: 13px;
  color: #9b9b9b;
`;

export const Orderimg = styled.img`
    display: block;
    width:190px;
    margin-left: auto;
    margin-right: auto;
`;

export const UpsandOntracimg = styled.img`
    display: block;
    width:90px;
    height:90px;
    margin-right: 40px;
`;

export const UpsAndOntracWrapper = styled.div`
    display : flex;
`;
export const Leftdiv = styled.div`

  margin-top:30px;

  margin-bottom:30px;

`;
export const Left = styled.p`
  font-size: 14px;

  font-family: Montserrat Medium;

  color: #d40202;
`;
export const P = styled.p`
  font-family: Montserrat Medium;
  text-align:center;
  padding-top:5px;
  margin:0;
  color: #282828;
`;
export const Pcity = styled.p`
  text-align:center;
  padding-top:5px;
  margin:0;
  color: #9b9b9b;
  font-size: 12px;
`;

export const OrderText = styled.div`
  position: relative;
  display:block;
  padding-top:20px;
  padding-bottom:20px;
`;
