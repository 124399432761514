import React, { Component } from 'react';
import {  Label, Input, Button, Div_margin, Div_wrapper, stateOptions,  Div_title , Headerimg, AnchoreDiv,HeaderFlexApp} from './style/First';
import './App.css';
import { withRouter } from 'react-router-dom';
import { Dropdown, Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

class App extends Component {
  constructor(props) {
    super(props);
      this.state = {
        value: '',
        zipcode : '',
        dropdown : '',
        isLoading: false,
        username: null ,
        error :false ,
        errorzip: false,
        droperror:false,
      };

    this.dropChange = this.dropChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.orderChange = this.orderChange.bind(this);
    this.zipChange = this.zipChange.bind(this);
  }

  handleClick(event) {
  if(this.state.dropdown == ""){
    this.setState({droperror: true});
  }else {
    this.setState({droperror: false});
    if(this.state.zipcode.match(/^[/\d]{5}?$/)){
      this.setState({errorzip: false});
      if (this.state.dropdown == "bestchoiceproducts"){
          if(this.state.value.match(/^[/\d]{7}?$/)){
             let path = `/orderstatus/${this.state.value}/${this.state.zipcode}`;
              this.props.history.push(path);
          }else{
             this.setState({error: true});
          }
      }else if (this.state.dropdown == "walmart"){
        if(this.state.value.match(/^[/\d]{13}?$/)){
           let path = `/orderstatus/${this.state.value}/${this.state.zipcode}`;
            this.props.history.push(path);
        }else{
           this.setState({error: true});
        }
      }else if(this.state.dropdown == "target"){
        if(this.state.value.match(/^([0-9]-?){23}$/)){
           let path = `/orderstatus/${this.state.value}/${this.state.zipcode}`;
            this.props.history.push(path);
        }else{
           this.setState({error: true});
        }
      }else if(this.state.dropdown == "wayfair"){
        if(this.state.value.match(/CS\d{9}/)){
           let path = `/orderstatus/${this.state.value}/${this.state.zipcode}`;
            this.props.history.push(path);
        }else{
           this.setState({error: true});
        }
      }else if(this.state.dropdown == "amazon"){
        if(this.state.value.match(/^([0-9]-?){17}$/)){
           let path = `/orderstatus/${this.state.value}/${this.state.zipcode}`;
            this.props.history.push(path);
        }else{
           this.setState({error: true});
        }
      }else if(this.state.dropdown == "homedepot"){
        if(this.state.value.match(/^([0-9]-?){8}$/)){
           let path = `/orderstatus/${this.state.value}/${this.state.zipcode}`;
            this.props.history.push(path);
        }else{
           this.setState({error: true});
        }
      }else if(this.state.dropdown == "google"){
        if(this.state.value.substring(0, 2) == "G-"){
           let path = `/orderstatus/${this.state.value}/${this.state.zipcode}`;
            this.props.history.push(path);
        }else{
           this.setState({error: true});
        }
      }
    }else{
      this.setState({errorzip: true});
    }
  }
}

orderChange(event) {
    this.setState({value: event.target.value});
  }
  zipChange(event) {
    this.setState({zipcode: event.target.value});
  }

  dropChange = (event, data) => {
      this.setState({dropdown: data.value});
    }
  render() {
    return (
      <div>
      <HeaderFlexApp>
        <div>
          <a href="https://bestchoiceproducts.com"><Headerimg src={"https://cdn.shopify.com/s/files/1/0636/7991/files/New-Logo-red-bcp_small.png?v=1634884818"} alt="header" /></a>
        </div>
      </HeaderFlexApp>
      <Div_wrapper>

        <Div_title>Order Tracking</Div_title>
        <br />
        <Div_margin>Enter your information below to view order status.</Div_margin>
        <div>
            {this.state.droperror  ? (
              <Div_margin>
                <Message color='red'>Please select a marketplace below</Message>
              </Div_margin>
            ) : (
              <div></div>
            )}
            {this.state.error  ? (
              <Div_margin>
                <Message color='red'>Please Enter Valid Order Number</Message>
              </Div_margin>
            ) : (
              <div></div>
            )}
            {this.state.errorzip  ? (
              <Div_margin>
                <Message color='red'>Please Enter Valid Billing Zipcode</Message>
              </Div_margin>
            ) : (
              <div></div>
            )}
         </div>
         <Div_margin>
           <Dropdown placeholder='Where did you place your order?' fluid selection options={stateOptions}  onChange={this.dropChange} />
         </Div_margin>
         <Div_margin>
           <Label>Order Number*</Label>
           <Input value={this.state.value} onChange={this.orderChange}/>
         </Div_margin>
         <Div_margin>
           <Label>Billing ZipCode*</Label>
           <Input value={this.state.zipcode} onChange={this.zipChange}/>
         </Div_margin>
        <Button type='submit' value="Submit" onClick={this.handleClick}>View Order Status</Button>
     </Div_wrapper>
     </div>
    );
  }
}

export default withRouter(App);
