import React, { Component } from 'react';
import {OrderStatus, Left, TrackingInfo, Leftdiv, Orderdiv,HeaderCard, Orderflex, Pcity, TextDiv, Orderimg,Rewardimage,EstimateDiv, Rewardimag, OrderText,OrderActivity, P} from './Resultcss';
import ActivityMap from './ActivityMap';
import {Div_Header, Headerimg, HeaderFlex, AnchoreDiv} from '../../style/First'
import deliverd from './shipping-delivered-02.svg'
import ResultBanner from './ResultBanner'

export default class Result extends Component {
  constructor(props) {
    super(props);
      this.state = {
        day: "Currently Unavailable",
        datee:"",
        username: null ,
        dayleft : "",

      };
  }

  componentDidMount() {
    this.getDay();
  }
  getDay = () => {

    if(Array.isArray(this.props.tracking.DatesOrTimes)){
      var orderdate = this.props.tracking.DatesOrTimes[0].DateOrTimestamp
      var date = new Date(orderdate);
      var today = new Date();
      var dd = today.getDate();
      var deliverdate = date.getDate();
      var daleft = deliverdate - dd;
      var days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
      var datee = orderdate.substring(0, 10);
      this.setState({ day : days[date.getDay()] });
      this.setState({ datee });
      this.setState({dayleft : daleft});
    }else{
      console.log("https://bestchoiceproducts.com");
    }

};
  render() {
      const track = this.props.tracking;

    return (
<div>
  	<HeaderFlex>
  		<div>
  			<a href="https://bestchoiceproducts.com"><Headerimg src={"https://cdn.shopify.com/s/files/1/0636/7991/files/New-Logo-red-bcp_small.png?v=1634884818"} alt="header" /></a>
  		</div>
  		<AnchoreDiv> <a href="https://orderstatus.bestchoiceproducts.com/" style={{ color: '#282828' }}>Track Another Order</a>
  		</AnchoreDiv>
  	</HeaderFlex>
  	<Orderdiv>
  		<div>
  			<div>
  				<OrderStatus>
  					<HeaderCard>Tracking Info</HeaderCard>
  					<Orderflex>
  						<EstimateDiv>
              <div>
                  {
                    track.Events[0].EventDescription == "Delivered" ? (
                      <TextDiv>Delievered Date</TextDiv>
                    ) : (
                      <TextDiv>Estimated Delivery Date</TextDiv>
                    )
                  }
                  </div>

              <div style={{ fontSize: '18px' }}>{this.state.day} {this.state.datee} </div>

              </EstimateDiv>
              <Leftdiv>
                {
                  track.Events[0].EventDescription == "Delivered" ? (
                    <Left>DELIVERED</Left>
                  ) : (
                    <Left> {this.state.dayleft} DAYS LEFT </Left>
                  )
                 }
              </Leftdiv>


  						<EstimateDiv>
  							<TextDiv>Tracking No.</TextDiv>
                    <a target="_blank" href={ "https://www.fedex.com/apps/fedextrack/?action=track&action=track&tracknumbers="+track.TrackingNumber } style={{ color: '#282828', fontSize: '18px' }}>{track.TrackingNumber}</a>
  							<div style= {{paddingTop : '10px;'}}>
                  {
                    this.props.ordernumber.indexOf(',') > -1 ? (
                      <a target="_blank" href={ "https://www.fedex.com/apps/fedextrack/?action=track&action=track&tracknumbers="+ this.props.ordernumber}>View More</a>
                    ) : (
                      <div></div>
                    )
                  }
  							</div>
  						</EstimateDiv>
  					</Orderflex>
  				</OrderStatus>
  			</div>
        <TrackingInfo>
      		<HeaderCard>Order Status</HeaderCard>
          <OrderText>
          <div>
            {
              track.Events[0].EventDescription == "Delivered" ? (
                <div>
                  <Orderimg src={deliverd} alt="DShipping" />
                  <P>Your Order Has Been Delievered</P>
                </div>
              ) : (
                <div>
                  <Orderimg src={require( './shipping.svg')} alt="Shipping" />
                  <P>Your Order Is In Transit</P>
                </div>
              )
             }
          </div>
            <Pcity>{track.DestinationAddress.City}, {track.DestinationAddress.StateOrProvinceCode}</Pcity>
          </OrderText>
      	</TrackingInfo>
  		</div>
  		<OrderActivity>
  			<HeaderCard>Activity</HeaderCard>
  			<ActivityMap activityevent={ track.Events} />
  		</OrderActivity>
  	</Orderdiv>

    <ResultBanner />

</div>

    );
  }
}
