import React, { Component } from 'react';
import {UpsOrderStatus, UpsAndOntracWrapper, UpsandOntracimg, TrackingInfo, Leftdiv, Orderdiv,HeaderCard, UpsOrderflex, Pcity, TextDiv, Orderimg,Rewardimage,EstimateDiv, Rewardimag, OrderText,OrderActivity, P} from './Resultcss';
import ResultBanner from './ResultBanner'
import {Div_Header, Headerimg, HeaderFlex, AnchoreDiv, UpsTrackingdiv} from '../../style/First'
import UpsImage from './ups.png'
import OntracImage from './ontrac.png'

export default class UpsAndOntrack extends Component {
  constructor(props) {
      super(props);
      this.state = {
        number_tracking : '',
        link_ups : '',
        multiple_tracking_number : false
      };
  }
  componentDidMount() {
    this.getTrackingNumber();
  }
  getTrackingNumber = () => {
    var new_track;
    var hey_link_ups;
   if(this.props.ordernumber.indexOf(',') > -1){
    new_track =  this.props.ordernumber.split(",")[0];
    hey_link_ups = this.props.ordernumber.replace(/,/g, '%0D%0A');
    this.setState({ link_ups: hey_link_ups });
    this.setState({ number_tracking : new_track });
    this.setState({multiple_tracking_number : true});
   }else {
    new_track = this.props.ordernumber;
    this.setState({ number_tracking : new_track });
   }
  }
  render() {
    return (
      <div>
        <HeaderFlex>
            <div>
              <a href="https://bestchoiceproducts.com"><Headerimg src={"https://cdn.shopify.com/s/files/1/0636/7991/files/New-Logo-red-bcp_small.png?v=1634884818"} alt="header" /></a>
            </div>
            <AnchoreDiv> <a href="http://orderstatus.bestchoiceproducts.com/" style={{ color: '#282828' }}>Track Another Order</a></AnchoreDiv>
        </HeaderFlex>
          <UpsTrackingdiv>
            <UpsOrderStatus>
              <HeaderCard>TRACKING INFO</HeaderCard>
              <UpsOrderflex>
                <EstimateDiv>
                  {
                    this.props.ordernumber.startsWith("1Z") ? (
                      <UpsAndOntracWrapper>
                        <UpsandOntracimg src={UpsImage} alt="Ups" />
                        <div>
                          <TextDiv style={{ paddingBottom: '10px' }}>Tracking No.</TextDiv>
                          <a target="_blank" href={ "https://www.ups.com/track?loc=en_US&tracknum="+this.state.number_tracking } style={{ color: '#282828', fontSize: '18px' }}>{this.state.number_tracking}</a>
                          <div style= {{paddingTop : '10px;'}}>
                            {
                              this.props.ordernumber.indexOf(',') > -1 ? (
                                <a target="_blank" href={ "https://www.ups.com/track?loc=en_US&tracknum="+ this.state.link_ups}>View More</a>
                              ) : (
                                <div></div>
                              )
                            }
                          </div>
                        </div>
                      </UpsAndOntracWrapper>
                    ):(
                      <UpsAndOntracWrapper>
                        <UpsandOntracimg src={OntracImage} alt="Ups" />
                        <div>
                        <TextDiv>Tracking No.</TextDiv>
                          <a target="_blank" href={ "https://www.ontrac.com/trackingresults.asp?tracking_number="+this.state.number_tracking } style={{ color: '#282828', fontSize: '18px' }}>{this.state.number_tracking}</a>
                          <div style= {{paddingTop : '10px;'}}>
                            {
                              this.props.ordernumber.indexOf(',') > -1 ? (
                                <a target="_blank" href={ "https://www.ontrac.com/trackingresults.asp?tracking_number="+ this.state.link_ups}>View More</a>
                              ) : (
                                <div></div>
                              )
                            }
                          </div>
                        </div>
                      </UpsAndOntracWrapper>
                    )
                  }
                </EstimateDiv>
              </UpsOrderflex>
            </UpsOrderStatus>
          </UpsTrackingdiv>
        <ResultBanner />
      </div>
    );
  }
}