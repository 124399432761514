import styled from 'styled-components';

export const HelpDIv = styled.div`
  width: 100%;
  height: 214px;

  padding-top:60px;
  background-color: #eeeeee;
  @media (max-width: 731px) {
    margin-top: 20px;
    height: 270px;
    padding-top:40px;
  }
`;
export const HelpcontentDIv = styled.div`
  display:flex;
  justify-content: center;
  @media (max-width: 731px) {
    display:block;
    text-align:center;
  }
`;

export const TextDiv = styled.div`
   margin-left:50px;
   @media (max-width: 731px) {
     margin-left:0px;
   }
`;

export const Helptext = styled.p`
  font-size:21px;
  text-align: left;
  @media (max-width: 731px) {
    text-align:center;
  }
`;

export const Helpbutton = styled.button`
    width: 230px;
    height: 39px;
    border-radius: 3px;
    color:#ffffff;
    font-size: 13px;
    cursor: pointer;
    background-color: #282828;
`;

export const HelpMain = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  max-width: 1260px;
  margin: 0px auto;
`;
export const HelpReward = styled.div`
margin: 10px 0px;
display:block;
@media (max-width: 731px) {
  display:none;
}
`;

export const HelpRewardMobile = styled.div`
  margin: 10px 0px;
  display:none;
  @media (max-width: 731px) {
    display:block;
  }
`;
